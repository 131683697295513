import { Container } from "@mui/material";
import React from "react";
import Layout from "../../layout";

const AboutUs = () => {
  return (
    <Layout>
      <Container>
        <div className="about-page">
          <h3 className="about-page-title">Hakkımızda</h3>
          <p className="about-page-para">
            Evrensel kardeşlik değerlerini referans alarak “Biz Bir Milletiz”
            sloganıyla yola çıkan Bâb-ı Âlem; farklı coğrafyalardan üniversite
            eğitimi almak için ülkemizi tercih eden misafir öğrencilere yönelik,
            gönüllü çalışmalar yapmak üzere kurulmuş uluslararası bir öğrenci
            derneğidir.
          </p>
          <p className="about-page-para">
            Misafir öğrencilerimizin Bâb-ı Âlem çatısı altında evrensel
            kardeşlik bilincine sahip, temel dinî bilgiler açısından donanımlı,
            modern dünyayı ve dünya sistemini tanıyan, organizasyon kabiliyeti
            yüksek, ülkesinin durumunun ve ihtiyaçlarının farkında, İslam kültür
            ve tarihinin ana hatlarına vâkıf, farklılıklara karşı müsamahalı
            birer “gönüllü medeniyet elçisi” olarak ülkelerine dönmeleri temel
            gayemizdir.
          </p>
          <p className="about-page-para">
            Bâb-ı Âlem, bünyesindeki öğrencilere eğitim ve rehberlik hizmeti
            vermenin yanında, misafir öğrencilere maddi ve manevi destek
            sağlamayı, öğrencilerin karşılaştığı her türlü sıkıntıda onlarla
            beraber olmayı bir misyon edinmiştir. Derneğimiz 2004 yılından bu
            yana aktif şekilde çalışmalarını yürütmektedir.
          </p>
          <p className="about-page-para">
            Türkiye’nin muhtelif şehirlerinde misafir öğrenci çalışması yapan
            derneklerden oluşan UDEF (Uluslararası Öğrenci Dernekleri
            Federasyonu) kurucu üyesi olan Bâb-ı Âlem Uluslararası Öğrenci
            Derneği, aynı zamanda İDSB (İslam Dünyası Sivil Toplum Kuruluşları
            Birliği) ve TGTV (Türkiye Gönüllü Teşekküller Vakfı) üyesidir.
          </p>

          <h3 className="about-page-title">Misyonumuz & Vizyonumuz</h3>
          <p className="about-page-para">
            Bâb-ı Âlem, yurtdışından Türkiye’ye eğitim için gelen öğrencilere,
            eğitim ve rehberlik hizmeti vermek amacıyla kurulmuş uluslararası
            bir öğrenci derneğidir.
          </p>
          <p className="about-page-para">
            “Biz Bir Milletiz” sloganıyla yola çıkan Bâb-ı Âlem, misafir
            öğrenciler için eğitim faaliyetleri ve sosyal etkinlikler
            düzenlemektedir. Bu sayede farklı coğrafyalardan gelmiş binlerce
            öğrenciye aynı milletin fertleri oldukları şuuru verilmektedir.
          </p>
          <p className="about-page-para">
            Bâb-ı Âlem Uluslararası Öğrenci Derneği, renk, dil, din, ırk,
            mezhep, bölge ayrımı yapmaksızın dünyanın dört bir yanından ülkemize
            eğitim için gelen misafir öğrencilere eğitim hayatları boyunca en
            iyi şekilde hizmet verebilmek için çalışan uluslararası bir sivil
            toplum kuruluşudur.
          </p>
          <p className="about-page-para">
            Faaliyetlerimiz halkımızın aynî ve nakdî yardımlarıyla hayat
            bulmaktadır. Bazı faaliyetlerimiz ilgili resmi kurumların, sivil
            toplum kuruluşlarının ve belediyelerin desteğiyle yürütülmektedir.
            Bâb-ı Âlem, misafir öğrencilerin eğitimi için aşağıda özetlenen
            farklı alanlarda faaliyet göstermektedir.
          </p>
          <p className="about-page-para">
            Medeniyet değerlerimizi önceleyerek, ilgilendiğimiz her bölgenin
            toplumsal, sosyal ve kültürel değerlerini göz önünde bulundurarak en
            iyi çalışmayı ortaya koymaya gayret ediyoruz.
          </p>
          <p className="about-page-para">
            Öğrencilerimizin, farklılıkları bir zenginlik unsuru olarak gören,
            yeryüzünde attığı her adımda kardeşlik bilincini hatırlayan birer
            fert olarak yetişmeleri en önemli amacımız.
          </p>
        </div>
      </Container>
      <div className="contact-bg"></div>
    </Layout>
  );
};

export default AboutUs;
