import { Container } from "@mui/system";
import React from "react";
import Layout from "../../layout";

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="contact-card">
          <div className="contact-float">
            <div className="contact-single">
              <h2>Hakkımızda</h2>
              <p>
                Bâb-ı Âlem Uluslararası Öğrenci Derneği yurtdışından ülkemize
                eğitim için gelen ve İstanbul’da eğitim gören misafir
                öğrencilere yönelik rehberlik, danışmanlık hizmetleri ile
                birlikte çeşitli eğitim ve sosyal aktiviteler
                gerçekleştirmektedir.
              </p>
            </div>
            <div className="contact-single contact-middle">
              <h2>Adres</h2>
              <p>
                Karagümrük Mahallesi Fevzipaşa Caddesi Hakan Pasajı <br /> No
                261/22 Fatih-İstanbul
              </p>
            </div>
            <div className="contact-single">
              <h2>İletişim</h2>
              <a href="mailto:info@babialem.org">info@babialem.org</a>
              <a href="tel:+90 212 621 2743">+90 212 621 2743</a>
            </div>
          </div>
        </div>
      </Container>

      <div className="contact-map">
        <iframe
          title="contact map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.9456261289!2d28.93666577662575!3d41.026445518305145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba217ac66451%3A0x34eef8a59ce284b8!2zQsOiYi3EsSDDgmxlbQ!5e0!3m2!1sen!2str!4v1703930921570!5m2!1sen!2str"
          width="100%"
          height="550"
          style={{ border: 0 }}
          allowfullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Contact;
